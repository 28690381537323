import React from "react";
import {Helmet} from "react-helmet";
import styles from "./index.module.css";
import {Link} from "gatsby";

export default class Impressum extends React.Component {

    render() {
        return (
            <>
                <Helmet htmlAttributes={{"lang": "de"}}
                >
                    <meta charSet="utf-8"/>
                    <title>Kreta Urlaub - Impressum &amp; Datenschutzvereinbarung</title>
                    <link rel="canonical" href="https://www.kreta-urlaub.at"/>
                </Helmet>
                <div className={styles.container}>
                    <div className={styles.imprintContainer}>
                        <h1>Impressum</h1>
                        <p>Herbert Heller<br/>Fichtenweg 7<br/>4533 Piberbach<br/>Österreich<br/>Email: <a
                            href="mailto:office@kreta-urlaub.at">office@kreta-urlaub.at</a><br/>Telefon: +43 (0) 676 4533111</p>
                        <p>Verantwortlich für Inhalt: Herbert Heller</p>
                        <p>Plattform der Europäischen Kommission zur Online-Streitbeilegung (OS) für Verbraucher: <a
                            href="https://ec.europa.eu/consumers/odr/"
                            target="_blank" rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>. Wir sind
                            nicht bereit und nicht
                            verpflichtet an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
                            teilzunehmen.</p>

                        <h1>Datenschutzvereinbarungen</h1>
                        <p></p><p>In dieser Datenschutzerklärung informieren wir Sie über die Verarbeitung Ihrer
                        personenbezogenen Daten.</p>
                        <h2>Verantwortlich</h2>
                        <p>Herbert Heller, Fichtenweg 7, 4533 Piberbach, AT, <a
                            href="mailto:office@kreta-urlaub.at">office@kreta-urlaub.at</a>, +43 (0) 664 111 7777</p>
                        <h2>Hosting Provider</h2>
                        <h3>Amazon AWS</h3>
                        <p></p><p>Wir hosten unsere Website bei unserem Auftragsverarbeiter <a target="_blank"
                                                                                               rel="noopener noreferrer"
                                                                                               href="https://aws.amazon.com/de/">Amazon
                        AWS</a>, Amazon Web Services, Inc., 410 Terry Avenue North, Seattle WA 98109, USA.</p>
                        <p>Zum Zweck der Bereitstellung und der Auslieferung der Website werden Verbindungsdaten
                            verarbeitet. Zum bloßen Zweck der Auslieferung und Bereitstellung der Website werden die
                            Daten über den Aufruf hinaus nicht gespeichert.</p>
                        <p>Die Rechtsgrundlage für die Datenverarbeitung ist das berechtigte Interesse (unbedingte
                            technische Notwendigkeit zur Bereitstellung und Auslieferung des von ihnen durch Ihren
                            Aufruf ausdrücklich gewünschten Dienstes "Website") gemäß Art. 6 Abs. 1 lit. f DSGVO.</p>
                        <p>Zum Betrieb der Website werden die Verbindungsdaten und weitere personenbezogenen Daten
                            zusätzlich im Rahmen diverser anderer Funktionen bzw. Dienste verarbeitet. Darüber wird im
                            Rahmen dieser Datenschutzerklärung bei den einzelnen Funktionen bzw. Diensten im Detail
                            informiert.</p>
                        <p>Die Rechtsgrundlage für die Übermittlung in die USA ist der Durchführungsbeschluss der
                            EU-Kommission <a target="_blank" rel="noopener noreferrer"
                                             href="https://op.europa.eu/en/publication-detail/-/publication/c183d956-57a6-11e6-89bd-01aa75ed71a1/language-de/"
                                             title="C/2016/4176">C/2016/4176</a> sowie die erfolgte <a target="_blank"
                                                                                                       rel="noopener noreferrer"
                                                                                                       href="https://www.privacyshield.gov/participant?id=a2zt0000000TOWQAA4&amp;status=Active"
                                                                                                       title="Privacy Shield">Privacy
                                Shield Zertifizierung</a>.</p>
                        <p></p>
                        <h2>CDN Anbieter</h2>
                        <h3>AWS Cloudfront CDN</h3>
                        <p></p><p>Wir hosten Teile unserer Website bei unserem Auftragsverarbeiter <a target="_blank"
                                                                                                      rel="noopener noreferrer"
                                                                                                      href="https://aws.amazon.com/de/cloudfront/">AWS
                        Cloudfront</a>, Amazon Web Services Inc., 410 Terry Avenue North, Seattle WA 98109, USA.</p>
                        <p>Zum Zweck der Bereitstellung und der Auslieferung der Website werden Verbindungsdaten
                            verarbeitet. Zum bloßen Zweck der Auslieferung und Bereitstellung der Website werden die
                            Daten über den Aufruf hinaus nicht gespeichert. Es erfolgt jedoch eine Speicherung der
                            Verbindungsdaten durch unseren Auftragsverarbeiter zu Sicherheitszwecken. Die Dauer der
                            Verarbeitung zu Sicherheitszwecken ist variabel und endet mit der Notwendigkeit der
                            Sicherheitsmaßnahmen. Zudem anonymisiert unser Auftragsverarbeiter die erhobenen Daten
                            unmittelbar nach der Erhebung und stellt uns die anonymen Daten in Form von Statistiken zur
                            Auswertung zur Verfügung. Wir nutzen diese Statistiken zur Fehlerbehebung und zur
                            Weiterentwicklung unserer Website.</p>
                        <p>Die Rechtsgrundlage für die Datenverarbeitung ist das berechtigte Interesse (unbedingte
                            technische Notwendigkeit zur Bereitstellung und Auslieferung des von ihnen durch Ihren
                            Aufruf ausdrücklich gewünschten Dienstes "Website") gemäß Art. 6 Abs. 1 lit. f DSGVO. Die
                            Rechtsgrundlage für die Übermittlung in die USA ist der Durchführungsbeschluss der
                            EU-Kommission <a target="_blank" rel="noopener noreferrer"
                                             href="https://op.europa.eu/en/publication-detail/-/publication/c183d956-57a6-11e6-89bd-01aa75ed71a1/language-de/"
                                             title="C/2016/4176">C/2016/4176</a> sowie die erfolgte <a target="_blank"
                                                                                                       rel="noopener noreferrer"
                                                                                                       href="https://www.privacyshield.gov/participant?id=a2zt0000000TOWQAA4&amp;status=Active"
                                                                                                       title="Privacy Shield">Privacy
                                Shield Zertifizierung</a>.</p>
                        <p></p>
                        <h2>Widerspruchsrecht</h2>
                        <p></p><p>Sofern die Verarbeitung Ihrer personenbezogenen Daten auf Grundlage des berechtigten
                        Interesses erfolgt, haben Sie das Recht gegen dieser Verarbeitung zu widersprechen.</p>
                        <p>Sofern keine zwingenden schutzwürdigen Gründe für die Verarbeitung unsererseits vorliegen,
                            wird die Verarbeitung Ihrer Daten auf Basis dieser Rechtsgrundlage eingestellt.</p>
                        <p>Die Rechtmäßigkeit der bis zum Widerspruch verarbeiteten Daten wird durch den Widerspruch
                            nicht berührt.</p>
                        <p></p>
                        <h2>Betroffenenrechte</h2>
                        <p></p><p>Sie haben zudem das Recht auf Auskunft, Berichtigung, Löschung und Einschränkung der
                        Verarbeitung der personenbezogenen Daten.</p>
                        <p>Soweit die Rechtsgrundlage der Verarbeitung Ihrer personenbezogenen Daten in Ihrer
                            Einwilligung oder in einem mit Ihnen abgeschlossenen Vertrag besteht, haben Sie überdies das
                            Recht auf Datenübertragbarkeit. </p>
                        <p>Weiters haben Sie das Recht auf Beschwerde bei der Aufsichtsbehörde. Nähere Informationen zu
                            den Aufsichtsbehörden in der Europäischen Union finden Sie <a target="_blank"
                                                                                          rel="noopener noreferrer"
                                                                                          href="https://ec.europa.eu/justice/article-29/structure/data-protection-authorities/index_en.htm"
                                                                                          title="Datenschutzbehörden">hier</a>.
                        </p><p></p>

                    </div>
                    <footer>
                        <Link to={"/"}>Startseite</Link>
                    </footer>
                </div>
            </>
        );
    }
}